import React, { useState } from "react"
import { Button, CardColumns, Card, Modal } from "react-bootstrap"
import { useAppState } from "../AppStateContext"
import { useHistory } from "react-router-dom"
import { FaPlus } from "react-icons/fa"
import { AddArenaModal } from "./AddArenaModal"
import { arenaPath } from "../paths"

export const ArenaList: React.FC = () => {
    const { state, dispatch } = useAppState()
    const [modalIsVisible, setModalIsVisible] = useState(false)
    const history = useHistory()

    const handleClose = () => setModalIsVisible(false)


    return (
        <div>
            <h1>Arenas</h1>
            <br />
            <CardColumns>
                {state.accountInfo?.arenas.map(arena => (
                    <Card key={arena.id} style={{ cursor: "pointer" }} onClick={() => history.push(arenaPath(arena.id))}>
                        <Card.Body>
                            <Card.Title>{arena.name}</Card.Title>
                        </Card.Body>
                    </Card>
                ))}

            </CardColumns>
            <br />
            <br />
            <Button variant="secondary" size="lg" block onClick={() => setModalIsVisible(true)}>
                Add New Arena &nbsp;&nbsp;<FaPlus />
            </Button>

            <AddArenaModal isVisible={modalIsVisible} handleClose={handleClose} />
        </div>
    )
}