import { postDeleteArena, postDeleteExample, postNewArena, postNewExample } from "../httpAPI"
import { v4 as uuidv4 } from "uuid"
import { SESSION_KEY } from "../constants"
import { Arena, Example } from "../AppStateContext"
import { getSessionId, isEmpty } from "./modelUtils"


export interface ArenaModalProps {
    isVisible: boolean
    handleClose(): void
}


export const saveArena = async (arena_name: string, dispatch: Function) => {


    const newArena = {
        id: uuidv4(),
        name: arena_name
    }

    const arena_post = {
        session_id: getSessionId(),
        arena_id: newArena.id,
        arena_name: newArena.name
    }
    dispatch({
        type: "ADD_ARENA",
        payload: { arena: newArena }
    })

    const accountInfo = await postNewArena(arena_post)

    if (isEmpty(accountInfo)) {
        dispatch({
            type: "REMOVE_ARENA",
            payload: { arena_id: newArena.id }
        })

        alert("There was a problem adding your arena.")
        return
    }

    dispatch({
        type: "REFRESH",
        payload: {
            isLoggedIn: true,
            accountInfo: accountInfo
        }
    })
}

export const deleteArena = async (arena: Arena, dispatch: Function) => {
    dispatch({
        type: "REMOVE_ARENA",
        payload: { arena_id: arena.id }
    })

    const accountInfo = await postDeleteArena({session_id: getSessionId(), arena_id: arena.id})

    if (isEmpty(accountInfo)) {
        dispatch({
            type: "ADD_ARENA",
            payload: { arena: arena }
        })

        alert("There was a problem deleting your arena.")
        return
    }

    dispatch({
        type: "REFRESH",
        payload: {
            isLoggedIn: true,
            accountInfo: accountInfo
        }
    })

}
