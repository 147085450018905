import { useParams, useHistory, Link } from "react-router-dom"
import { Arena, useAppState } from "../AppStateContext"
import React, { useState } from "react"
import { ExampleList } from "./ExampleList"
import { FaTrashAlt } from "react-icons/all"
import { DeleteArenaModal } from "./DeleteArenaModal"
import { arenaListPath } from "../paths"

export const ArenaDetail: React.FC = () => {
    let { arena_id } = useParams();
    const {state, dispatch } = useAppState();
    const [modalIsVisible, setModalIsVisible] = useState(false)
    const history = useHistory();

    const arena: Arena = state.accountInfo!.arenas.find(a => a.id === arena_id)!
    return <div>
        <Link to={arenaListPath}>&larr; Back</Link>
        <span
            className="float-right"
              style={{cursor: "pointer"}}
            onClick={() => setModalIsVisible(true)}>
            <FaTrashAlt />

        </span>
        <h1>{arena.name}</h1>
        <hr />

        <ExampleList arena_id={arena_id} />

        <DeleteArenaModal isVisible={modalIsVisible} handleClose={() => setModalIsVisible(false)} arena={arena} />
    </div>
}

