import React, { useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { Arena, Example, useAppState } from "../AppStateContext"
import { FaTrashAlt } from "react-icons/all"
import { ExampleList } from "./ExampleList"
import { DeleteArenaModal } from "./DeleteArenaModal"
import { arenaPath } from "../paths"
import { DeleteExampleModal } from "./DeleteExampleModal"
import { Button, Card, FormControl } from "react-bootstrap"

export const ExampleDetail: React.FC = () => {
    let { arena_id, example_id } = useParams()
    const { state, dispatch } = useAppState()
    const [modalIsVisible, setModalIsVisible] = useState(false)
    const history = useHistory()

    const arena: Arena = state.accountInfo!.arenas.find(a => a.id === arena_id)!
    const initialExample: Example = arena.examples.find(e => e.id === example_id)!

    const [example, setExample] = useState(initialExample)
    const [description, setDescription] = useState("Enter text...")


    return <div>
        <Link to={arenaPath(arena_id)}>&larr; Back</Link>
        <span
            className="float-right"
            style={{ cursor: "pointer" }}
            onClick={() => setModalIsVisible(true)}>
            <FaTrashAlt/>

        </span>
        <h1>{example.name}</h1>
        <hr/>
        {example.detail && example.detail.description ?
            <p>{example.detail.description}</p>
            :
            <Card>
                <Card.Body>
                    <FormControl
                        as="textarea"
                        aria-label="With textarea"
                        placeholder={description}
                        onChange={(e) => {setDescription(e.target.value)}}
                    />
                    <br />
                    <Button
                        size="sm"
                        className="float-right"
                        onClick={(e) => {
                            setExample({...example, detail: {description: description}})
                            setDescription("")
                        }}>
                        Update
                    </Button>
                </Card.Body>
            </Card>
        }

        <DeleteExampleModal
            isVisible={modalIsVisible}
            handleClose={() => setModalIsVisible(false)}
            example={example}
            arena={arena}
        />
    </div>
}