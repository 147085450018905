import React, { useState } from "react"
import { useAppState } from "../AppStateContext"
import { Button, Form, Modal } from "react-bootstrap"
import { saveExample } from "../model/exampleModel"

interface AddExampleProps {
    arena_id: string
    isVisible: boolean
    handleClose: Function
}

export const AddExampleModal = ({arena_id, isVisible, handleClose}: AddExampleProps) => {
    const [formData, setFormData] = useState({example_name: ""})
    const { state, dispatch } = useAppState()

    const handleAdd = ({ example_name }: { example_name: string }) => {
        saveExample(arena_id, example_name, dispatch)
        handleClose()
    }

    return (
        <Modal show={isVisible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a New Example</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Form>
                    <Form.Group controlId="exampleName">
                        <Form.Control type="text" placeholder="Enter Example Name"
                                      onChange={(event) => setFormData({ example_name: event.target.value })}/>
                    </Form.Group>
                    <Button className="float-right" variant="primary" onClick={() => handleAdd(formData)}>
                        Add
                    </Button>
                </Form>
            </Modal.Body>

        </Modal>
    )
}