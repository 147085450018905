import { Arena, Example, useAppState } from "../AppStateContext"
import { useHistory } from "react-router-dom"
import { arenaListPath, arenaPath } from "../paths"
import { deleteArena } from "../model/dataModel"
import { Button, Form, Modal } from "react-bootstrap"
import React from "react"
import { deleteExample } from "../model/exampleModel"

interface DeleteExampleModalProps {
    isVisible: boolean
    handleClose: Function
    example: Example
    arena: Arena
}

export const DeleteExampleModal = ({ isVisible, handleClose, example, arena }: DeleteExampleModalProps) => {
    const history = useHistory()
    const { state, dispatch } = useAppState()

    const handleDelete = () => {
        history.push(arenaPath(arena.id))
        deleteExample(arena.id, example, dispatch)
        handleClose()
    }

    return (
        <Modal show={isVisible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a New Arena</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Form>
                    <p>Delete the example, <strong>{example.name}?</strong></p>
                    <Button style={{ margin: "5px" }} className="float-right" variant="secondary"
                            onClick={(e) => handleDelete()}>
                        Yes Delete
                    </Button>
                    <Button style={{ margin: "5px" }} className="float-right" variant="primary"
                            onClick={(e) => handleClose()}>
                        Nevermind
                    </Button>
                </Form>
            </Modal.Body>

        </Modal>
    )
}