import React from 'react'
import logo from './logo.svg'
import { Container, Nav, Navbar } from "react-bootstrap"
import { WelcomeJumbotron } from './welcome/WelcomeJumbotron'
import { BrowserRouter as Router, Route, Link, useHistory, NavLink } from "react-router-dom"
import { useAppState } from "./AppStateContext"
import { Landing } from "./Landing"
import { UserHome } from "./UserHome"
import { logoutUser } from "./httpAPI"


function App() {
    const { state, dispatch } = useAppState()
    const history = useHistory()
    console.log("Actual app state...")
    console.log(state)

    return (
        <Router>
            <div className="App">
                <Navbar bg='dark' variant='dark'>
                    <Container>

                        <Navbar.Brand href="/">SkillTracker</Navbar.Brand>
                        <Navbar.Collapse className="justify-content-end">
                            {state.isLoggedIn &&
                            <Navbar.Brand>{state.accountInfo!.first_name} {state.accountInfo!.last_name}</Navbar.Brand>}
                            {state.isLoggedIn && <Navbar.Brand>
                              <Link
                                style={{color: "inherit"}}
                                to="/"
                                onClick={
                                    () => {
                                        logoutUser(dispatch)
                                    }
                                }
                              >
                                Logout
                              </Link>
                            </Navbar.Brand>}
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <br/>

                {state.isLoggedIn ? <UserHome/> : <Landing/>}
            </div>
        </Router>
    )
}

export default App
