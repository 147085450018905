import { Button, Form, Modal } from "react-bootstrap"
import React, { useState } from "react"
import { useAppState } from "../AppStateContext"
import { saveArena, ArenaModalProps } from "../model/dataModel"

interface AddArenaProps {
    isVisible: boolean
    handleClose: Function
}

export const AddArenaModal = ({isVisible, handleClose}: AddArenaProps) => {
    const [formData, setFormData] = useState({arena_name: ""})
    const { state, dispatch } = useAppState()

    const handleAdd = ({ arena_name }: { arena_name: string }) => {
        saveArena(arena_name, dispatch)
        handleClose()
    }

    return (
        <Modal show={isVisible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a New Arena</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Form>
                    <Form.Group controlId="arenaName">
                        <Form.Control type="text" placeholder="Enter Arena Name"
                                      onChange={(event) => setFormData({ arena_name: event.target.value })}/>
                    </Form.Group>
                    <Button className="float-right" variant="primary" onClick={() => handleAdd(formData)}>
                        Add
                    </Button>
                </Form>
            </Modal.Body>

        </Modal>
    )
}