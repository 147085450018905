import { Form, Button } from "react-bootstrap"
import React, { useState } from "react"
import { SignUpInfo, useAppState } from "../AppStateContext"
import { useHistory, Link } from "react-router-dom"
import { loginUser } from "../httpAPI"

export const LoginForm: React.FC = () => {
    const [formData, setFormData] = useState({} as SignUpInfo)
    const history = useHistory()
    const { state, dispatch } = useAppState()

    return (
        <div>
            <Link to="/">&larr; Back</Link>
            <br />
            <br />
            <Form>

                <Form.Group controlId="skillTrackerAccountEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email"
                                  onChange={(event) => setFormData({ ...formData, email: event.target.value })}/>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password"
                                  onChange={(event) => setFormData({ ...formData, password: event.target.value })}/>
                </Form.Group>
                <Button variant="primary" type="button" onClick={() => {
                    loginUser(formData, dispatch, () => history.push("/"))
                }}>
                    Submit
                </Button>
            </Form>
        </div>
    )
}