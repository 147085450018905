import { Button, Card, CardColumns } from "react-bootstrap"
import React, { useState } from "react"
import { Arena, useAppState } from "../AppStateContext"
import { useHistory } from "react-router-dom"
import { FaPlus } from "react-icons/fa"
import { AddExampleModal } from "./AddExampleModal"
import { examplePath } from "../paths"

export const ExampleList = ({arena_id}: {arena_id: string}) => {
    const { state, dispatch } = useAppState()
    const history = useHistory()
    const [modalIsVisible, setModalIsVisible] = useState(false)

    const handleClose = () => setModalIsVisible(false)

    const arena: Arena = state.accountInfo!.arenas.find(a => a.id === arena_id)!

    return (
        <div>
            <CardColumns>
                {arena.examples.map(example => (
                    <Card key={example.id} style={{ cursor: "pointer" }}
                          onClick={() => history.push(examplePath(arena_id, example.id))}>
                        <Card.Body>
                            <Card.Title>{example.name}</Card.Title>
                        </Card.Body>
                    </Card>
                ))}

            </CardColumns>

            <Button variant="secondary" size="lg" block onClick={() => setModalIsVisible(true)}>
                Add New Example &nbsp;&nbsp;<FaPlus/>
            </Button>

            <AddExampleModal
                arena_id={arena_id}
                isVisible={modalIsVisible}
                handleClose={handleClose}
            />
        </div>
    )
}