import { SignUpInfo, AppState, AccountInfo, LoginInfo, Arena } from "./AppStateContext"
import { SESSION_KEY } from "./constants"

// const endpoint = "http://127.0.0.1:8000"
const endpoint = "https://staging-by-example.herokuapp.com"

export const loginUser = (payload: LoginInfo, dispatch: Function, callback: Function) => {
    fetch(`${endpoint}/login`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    }).then(parseJson)
        .then(data => {
            const action = {
                type: "LOGIN_USER",
                payload: data
            }
            dispatch(action)
            localStorage.setItem(SESSION_KEY, data.session_id)
            callback()
        }).catch(console.log)
}

export const logoutUser = (dispatch: Function) => {
    const action = {
        type: "LOGOUT_USER",
    }
    dispatch(action)
    localStorage.removeItem(SESSION_KEY)
}

export const createNewUser = (payload: SignUpInfo, dispatch: Function) => {
    fetch(`${endpoint}/create-account`, {
        method: "POST",
        // credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    }).then(parseJson)
        .then((data: AccountInfo) => {
            console.log(data)
            const action = {
                type: "LOGIN_USER",
                payload: data
            }
            dispatch(action)
            localStorage.setItem(SESSION_KEY, data.session_id)
        }).catch(console.log)
}

export const fetchAccountInfo = (sessionId: string): Promise<AccountInfo> => {
    return fetch(`${endpoint}/session`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ "session_id": sessionId })
    }).then(response => {
        if (response.status === 404) {
            console.log("Session Id not found. Logging out.")
            localStorage.removeItem(SESSION_KEY)
            return {} as Promise<AccountInfo>
        } else {
            return response.json() as Promise<AccountInfo>
        }
    })
}

export const postNewArena = (payload: { session_id: string, arena_id: string, arena_name: string }): Promise<AccountInfo> => {
    return fetch(`${endpoint}/add-arena`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload)
    }).then(checkAndParse)
}

export const postDeleteArena = (payload: { session_id: string, arena_id: string }) => {
    return fetch(`${endpoint}/delete-arena`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload)
    }).then(checkAndParse)
}

export const postNewExample = (payload: { session_id: string, arena_id: string, example_id: string, example_name: string }) => {
    console.log(`Hitting ${endpoint}/add-example`)
    return fetch(`${endpoint}/add-example`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload)
    }).then(checkAndParse)
}

export const postDeleteExample = (payload: {session_id: string, example_id: string}) => {
    return fetch(`${endpoint}/delete-example`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload)
    }).then(checkAndParse)
}

const parseJson = (response: Response) => (response.json())

const checkAndParse = (response: Response): Promise<AccountInfo> => {
    if (response.status >= 200 && response.status < 300) {
        return response.json() as Promise<AccountInfo>
    } else {
        const error = new Error(`HTTP Error ${response.status} ${response.statusText}`)
        console.log(error)
        return {} as Promise<AccountInfo>
    }
}
