import React, { PropsWithChildren, ComponentType, useState } from "react"
import { AccountInfo, AppState, useAppState } from "./AppStateContext"
import { fetchAccountInfo } from "./httpAPI"
import { SESSION_KEY } from "./constants"

export const withData = (
    WrappedComponent: ComponentType<PropsWithChildren<{ initialState: AppState }>>
) => {
    return ({ children }: PropsWithChildren<{}>) => {

        const initialLoadingState = true
        const [isLoading, setIsLoading] = useState(initialLoadingState)
        const [error, setError] = useState<Error | undefined>()

        const [initialState, setInitialState] = useState<AppState>({
            isLoggedIn: false,
            accountInfo: undefined
        })

        React.useEffect(() => {
                const getSession = async () => {
                    const sessionId = localStorage.getItem(SESSION_KEY)
                    console.log("Getting session: " + sessionId)
                    if (sessionId) {
                        const accountInfo = await fetchAccountInfo(sessionId)
                        const initalState = {
                            isLoggedIn: true,
                            accountInfo: accountInfo,
                        }
                        setInitialState(initalState)
                    }
                    setIsLoading(false)
                }
                getSession()
            }, []
        )

        if (isLoading) {
            return <div>Loading</div>
        }

        if (error) {
            return <div>{error.message}</div>
        }

        return (
            <WrappedComponent initialState={initialState}>
                {children}
            </WrappedComponent>
        )
    }
}