import { Button, Jumbotron } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { loginPath, createAccountPath } from "../paths"
import React from "react"


export const WelcomeJumbotron: React.FC = () => {
    const history = useHistory()
    return (
        <Jumbotron>
            <h1>Hey, there!</h1>
            <p>
                Welcome to this Skill Tracker. It will help you keep track of your
                cool new skills.
            </p>
            <br/>
            <p>
                You can
                &nbsp;<Button style={{marginTop: "3px", marginBottom: "3px"}} onClick={() => history.push(loginPath)} variant="primary">Log In</Button>
                &nbsp;or
                &nbsp;<Button style={{marginTop: "3px", marginBottom: "3px"}} onClick={() => history.push(createAccountPath)}>Create an Account</Button>
            </p>
        </Jumbotron>
    )
}