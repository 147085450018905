import React from "react"
import { useAppState } from "./AppStateContext"
import { Container } from "react-bootstrap"
import { WelcomeJumbotron } from "./welcome/WelcomeJumbotron"
import { Route, Switch } from "react-router-dom"
import { ArenaList } from "./user/ArenaList"
import { ArenaDetail } from "./user/ArenaDetail"
import { ExampleDetail } from "./user/ExampleDetail"
import { arenaPath, examplePath } from "./paths"

export const UserHome = () => {
    const { state, dispatch } = useAppState()

    return <Container>
        <Switch>
            <Route path="/" exact component={ArenaList}/>
            <Route exact path={arenaPath(":arena_id")} component={ArenaDetail}/>
            <Route exact path={examplePath(":arena_id", ":example_id")} component={ExampleDetail} />
        </Switch>
    </Container>
}