import { v4 as uuidv4 } from "uuid"
import { getSessionId, isEmpty } from "./modelUtils"
import { postDeleteExample, postNewExample } from "../httpAPI"
import { Example } from "../AppStateContext"

export const saveExample = async (arena_id: string, example_name: string, dispatch: Function) => {

    const newExample = {
        id: uuidv4(),
        name: example_name
    }

    const example_post = {
        session_id: getSessionId(),
        arena_id: arena_id,
        example_id: newExample.id,
        example_name: newExample.name
    }
    dispatch({
        type: "ADD_EXAMPLE",
        payload: { arena_id: arena_id, example: newExample }
    })

    const accountInfo = await postNewExample(example_post)

    if (isEmpty(accountInfo)) {
        dispatch({
            type: "REMOVE_EXAMPLE",
            payload: { arena_id: arena_id, example_id: newExample.id }
        })

        alert("There was a problem adding your example.")
        return
    }

    dispatch({
        type: "REFRESH",
        payload: {
            isLoggedIn: true,
            accountInfo: accountInfo
        }
    })
}

export const deleteExample = async (arena_id: string, example: Example, dispatch: Function) => {
    dispatch({
        type: "REMOVE_EXAMPLE",
        payload: { arena_id: arena_id, example_id: example.id }
    })

    const accountInfo = await postDeleteExample({ session_id: getSessionId(), example_id: example.id })

    if (isEmpty(accountInfo)) {
        dispatch({
            type: "ADD_EXAMPLE",
            payload: { arena_id: arena_id, example: example }
        })

        alert("There was a problem deleting your arena.")
        return
    }

    dispatch({
        type: "REFRESH",
        payload: {
            isLoggedIn: true,
            accountInfo: accountInfo
        }
    })
}