import React, { createContext, useReducer, useContext, useEffect } from "react"
import { v4 as uuidv4 } from 'uuid'
import { withData } from "./withData"
import { fetchAccountInfo, postNewArena } from "./httpAPI"

export interface LoginInfo {
    email: string
    password: string
}

export interface SignUpInfo {
    firstName: string
    lastName: string
    email: string
    password: string
}

export interface Arena {
    id: string
    name: string
    examples: Example[]
}

export interface Example {
    id: string
    name: string
    detail: {
        description: string
    }
}

export type AccountInfo = {
    id: string,
    first_name: string,
    last_name: string,
    session_id: string,
    arenas: Arena[]
}

export type Action =
    | {
    type: "REFRESH"
    payload: AppState
} | {
    type: "LOGIN_USER"
    payload: AccountInfo
} | {
    type: "LOGOUT_USER"
} | {
    type: "ADD_ARENA"
    payload: { arena: Arena }
} | {
    type: "REMOVE_ARENA"
    payload: { arena_id: string }
} | {
    type: "ADD_EXAMPLE"
    payload: { arena_id: string, example: Example }
} | {
    type: "REMOVE_EXAMPLE"
    payload: { arena_id: string, example_id: string }
}

export interface AppState {
    isLoggedIn: boolean
    accountInfo: AccountInfo | undefined
}

const appStateReducer = (state: AppState, action: Action): AppState => {
    switch (action.type) {
        case "LOGIN_USER": {
            return { ...state, isLoggedIn: true, accountInfo: action.payload }
        }
        case "LOGOUT_USER": {
            return {
                ...state,
                isLoggedIn: false,
                accountInfo: undefined
            }
        }
        case "REFRESH": {
            return action.payload
        }
        case "ADD_ARENA": {
            const arenas = state.accountInfo!.arenas.concat([action.payload.arena])
            return {
                ...state,
                accountInfo: {
                    ...state.accountInfo,
                    arenas: arenas
                } as AccountInfo
            }
        }
        case "REMOVE_ARENA": {
            const index = state.accountInfo!.arenas.findIndex(a => a.id === action.payload.arena_id)
            return {
                ...state,
                accountInfo: {
                    ...state.accountInfo,
                    arenas: [
                        ...state.accountInfo!.arenas.slice(0, index),
                        ...state.accountInfo!.arenas.slice(index + 1, state.accountInfo!.arenas.length),
                    ]
                } as AccountInfo
            }
        }
        case "ADD_EXAMPLE": {
            const index = state.accountInfo!.arenas.findIndex(a => a.id === action.payload.arena_id)

            if (index === -1) {
                throw new Error("Arena Id not found")
            }

            const oldArena = state.accountInfo!.arenas[index]
            const examples = oldArena.examples.concat([action.payload.example])
            const newArena = {
                ...oldArena,
                examples
            }
            return {
                ...state,
                accountInfo: {
                    ...state.accountInfo,
                    arenas: [
                        ...state.accountInfo!.arenas.slice(0, index),
                        newArena,
                        ...state.accountInfo!.arenas.slice(index + 1, state.accountInfo!.arenas.length),
                    ]
                } as AccountInfo
            }
        }
        case "REMOVE_EXAMPLE": {
            const arenaIndex = state.accountInfo!.arenas.findIndex(a => a.id === action.payload.arena_id)
            const oldArena = state.accountInfo!.arenas[arenaIndex]
            const exampleIndex = oldArena.examples.findIndex(a => a.id === action.payload.example_id)


            const newArena = {
                ...oldArena,
                examples: [
                    ...oldArena.examples.slice(0, exampleIndex),
                    ...oldArena.examples.slice(exampleIndex + 1, oldArena.examples.length)
                ]
            }

            return {
                ...state,
                accountInfo: {
                    ...state.accountInfo,
                    arenas: [
                        ...state.accountInfo!.arenas.slice(0, arenaIndex),
                        newArena,
                        ...state.accountInfo!.arenas.slice(arenaIndex + 1, state.accountInfo!.arenas.length),
                    ]
                } as AccountInfo
            }
        }
        default:
            console.log(`Action type ${action!.type} not recognized.`)
            return state
    }
}


interface AppStateContextProps {
    state: AppState,
    dispatch: React.Dispatch<any>
}

const AppStateContext = createContext<AppStateContextProps>({} as AppStateContextProps)

export const AppStateProvider = withData(({ children, initialState }: React.PropsWithChildren<{ initialState: AppState }>) => {
    const [state, dispatch] = useReducer(appStateReducer, initialState)

    return (
        <AppStateContext.Provider
            value={{ state, dispatch }}
        >
            {children}
        </AppStateContext.Provider>
    )
})

export const useAppState = () => {
    return useContext(AppStateContext)
}