import { Route, Switch } from "react-router-dom"
import { WelcomeJumbotron } from "./welcome/WelcomeJumbotron"
import { CreateAccountForm } from "./welcome/CreateAccountForm"
import { Container } from "react-bootstrap"
import React from "react"
import { LoginForm } from "./welcome/LoginForm"
import { loginPath, createAccountPath } from "./paths"


export const Landing = () => {

    return <Container>
        <Switch>
            <Route path="/" exact component={WelcomeJumbotron}/>
            <Route path={createAccountPath} exact component={CreateAccountForm}/>
            <Route path={loginPath} exact component={LoginForm}/>
        </Switch>
    </Container>
}