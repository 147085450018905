import React, { useState } from "react"
import { Arena, useAppState } from "../AppStateContext"
import { deleteArena, ArenaModalProps } from "../model/dataModel"
import { useHistory } from "react-router-dom"
import { Button, Form, Modal } from "react-bootstrap"
import { arenaListPath } from "../paths"

interface DeleteArenaModalProps {
    isVisible: boolean
    handleClose: Function
    arena: Arena
}

export const DeleteArenaModal = ({isVisible, handleClose, arena}: DeleteArenaModalProps) => {
    const history = useHistory();
    const { state, dispatch } = useAppState()

    const handleDelete = () => {
        history.push(arenaListPath)
        deleteArena(arena, dispatch)
        handleClose()
    }

    return (
        <Modal show={isVisible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a New Arena</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Form>
                    <p>Delete the arena, <strong>{arena.name}?</strong></p>
                    <Button style={{margin: "5px"}} className="float-right" variant="secondary" onClick={(e) => handleDelete()}>
                        Yes Delete
                    </Button>
                    <Button style={{margin: "5px"}} className="float-right" variant="primary" onClick={(e) => handleClose()}>
                        Nevermind
                    </Button>
                </Form>
            </Modal.Body>

        </Modal>
    )
}